import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import aboutpic1 from "./../../images/about/pic1.jpg";
import aboutpic2 from "./../../images/about/pic2.jpg";
import aboutpic3 from "./../../images/about/pic3.jpg";
import aboutpic4 from "./../../images/about/pic4.jpg";

const Index4Tab = () => {
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <>
            <div className="dez-tabs">
                <div className="m-auto text-center tab-market">
                    <ul className="nav nav-tabs">
                        <li>
                            <Link
                                to={"#"}
                                className={classnames({ active: activeTab === "1" }) + ""}
                                onClick={() => {
                                    toggle("1");
                                }}
                            >
                                <i className="ti-mobile"></i>
                                <span className="title-head">Social Media</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={"#"}
                                className={classnames({ active: activeTab === "2" }) + ""}
                                onClick={() => {
                                    toggle("2");
                                }}
                            >
                                <i className="ti-pin"></i>
                                <span className="title-head">Immobilien</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={"#"}
                                className={classnames({ active: activeTab === "3" }) + ""}
                                onClick={() => {
                                    toggle("3");
                                }}
                            >
                                <i className="ti-video-camera"></i>
                                <span className="title-head">Werbefilm</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={"#"}
                                className={classnames({ active: activeTab === "4" }) + ""}
                                onClick={() => {
                                    toggle("4");
                                }}
                            >
                                <i className="ti-stats-up"></i>
                                <span className="title-head">Umsetzung</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="tab-content m-t50 text-black">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div id="web-design" className="tab-pane show fade">
                                <div className="row">
                                    <div className="col-lg-8 m-b30">
                                        <div className="box">
                                            <h3 className="font-40">SOCIAL MEDIA</h3>
                                            <h3 className="font-20">INSTAGRAM, FACEBOOK & VIELE WEITERE.</h3>
                                            <p>
                                                Instagram ist mehr als nur bunte Bilder. Es ist die kostengünstigste Art, seine Produkte oder Dienstleistungen zu vermarkten. Doch Social Media frisst Zeit. Deine Zeit. Und bei Zeitmangel wählt man
                                                unter Umständen die falsche Strategie. Mit der falschen Strategie wiederum wirft man schlechtem Geld, Gutes hinterher. Wir sorgen dafür dass Dein Social-Media Autritt perfekt, aktuell und erfolgreich
                                                wird. Mit anderen Worten: Wir sparen Deine Zeit, Dein Geld und eine Menge Kopfzerbrechen. Wie? Laß uns reden...
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={aboutpic1} className="radius-sm" alt="" />
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div id="web-development" className="tab-pane show fade">
                                <div className="row">
                                    <div className="col-lg-4 m-b30">
                                        <img src={aboutpic2} className="radius-sm" alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="box">
                                            <h3 className="font-40">IMMOBILIEN</h3>
                                            <p>
                                                Der Wettbewerb findet Online statt Sich bei über 10.000 Gästebetten durchzusetzen, ist hin und wieder schwierig. Denn Dein potentieller Kunde vergleicht die Angebote. Nicht nur Lage und Service müssen
                                                stimmen.. Deinen Service kann er vielleicht erahnen, die Lage steht natürlich fest. Doch die Darstellung des Ambientes bedarf ausgefeilterer Ideen. Deshalb solltest Du Dein Zimmer visualisieren. Dank
                                                High-End Material sind wir in der Lage, die Zimmerbuchung zu einem Erlebnis werden zu lassen. Es ist gar nicht so teuer wie Du denkst….
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div id="mobile-app" className="tab-pane active show fade">
                                <div className="row">
                                    <div className="col-lg-8 m-b30">
                                        <div className="box">
                                            <h3 className="font-40">WERBEFILME </h3>
                                            <h3 className="font-20">Das bessere Foto</h3>
                                            <p>
                                                Gute Fotos sind wichtig, keine Frage. Doch Emotionen lassen sich über bewegte Bilder wesentlich besser transportieren. Mit einer kleinen Story wird aus dem Werbefilm bereits ein Mini-Blockbuster. Wir
                                                sorgen dafür, dass Interessenten zu Kunden werden. Und zwar zu Deinen Kunden. Dank unseres innovativen Abrechnungssystems hast Du die volle Kostenkontrolle.
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4">
                                        <img src={aboutpic3} className="radius-sm" alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div id="internet-marketing" className="tab-pane show fade">
                                <div className="row">
                                    <div className="col-lg-4 m-b30">
                                        <img src={aboutpic4} className="radius-sm" alt="" />
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="box">
                                            <h3 className="font-40">Umsetzung</h3>
                                            <h3 className="font-20">DURCHDACHT. NACHHALTIG. RELEVANT.</h3>
                                            <p>
                                                Es gibt verschiedene Ziele im Online-Marketing, die Sie mit uns erreichen können.
                                                <br></br>
                                                Wir erstellen für Sie den Maßnahmenkatalog aus dem Werkzeugkasten des Online-Marketings, mit dem Sie die angestrebten Marketingziele erreichen.
                                                <br></br>
                                                Wir machen Sie im Sozial Network sichtbarer und präsenter, so dass mehr Menschen von Ihren Produkten und Dienstleistungen erfahren.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </>
    );
};

export default Index4Tab;
