import React, { Component } from "react";
import FormStyle from "../Element/FormStyle";
import bgimg from "./../../images/main-slider/slide6.jpg";
import logo from "./../../images/logo-color-2.png";

import bg1 from "./../../images/background/bg1.jpg";
// import { Link } from 'react-router-dom';

class Contact extends Component {
    render() {
        return (
            <>
                {" "}
                <div className="page-content bg-white rubik">
                    <div className="home-banner home-banner-short bg-img-fix m-b80" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                        <div className="home-bnr-inner">
                            <div className="home-bnr-content">
                                <img className="index-logo" src={logo} alt="Logo" />
                                <div className="home-bnr-btns"></div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- contact area --> */}
                    <div className="content-block">
                        <div className=" bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Contact;
