import React, { Component } from "react";
import Index4Tab from "../Element/Index4Tab";
import FormStyle from "../Element/FormStyle";

//Images..
import bg1 from "./../../images/background/bg1.jpg";
import sepp_rabea from "./../../images/about/SeppRabea.jpg";

import MainBanner from "../Element/MainBanner";

class Index4 extends Component {
    componentDidMount() {
        var i = 0;

        // Placeholder Animation Start
        var inputSelector = document.querySelectorAll("input, textarea");

        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener("focus", function (event) {
                return this.parentElement.parentElement.classList.add("focused");
            });
        }

        for (i = 0; i < inputSelector.length; i++) {
            inputSelector[i].addEventListener("blur", function (event) {
                var inputValue = this.value;
                if (inputValue === "") {
                    this.parentElement.parentElement.classList.remove("filled");
                    this.parentElement.parentElement.classList.remove("focused");
                } else {
                    this.parentElement.parentElement.classList.add("filled");
                }
            });
        }
    }
    render() {
        return (
            <>
                <div className="page-content bg-white rubik">
                    <MainBanner />

                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner about-progress">
                            <div className="container">
                                <div className="section-full content-inner-2">
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col-lg-5 col-md-4 about-img">
                                                <img src={sepp_rabea} data-tilt alt="" />
                                            </div>
                                            <div className="col-lg-7 col-md-8">
                                                <div className="abuot-box left row m-lr0 ">
                                                    <div className="col-lg-12">
                                                        <h2 className="box-title m-tb0">
                                                            ÜBER UNS<span className="bg-primary"></span>
                                                        </h2>
                                                        <p>
                                                            Hast du schon gehört?
                                                            <br></br>
                                                            Gerüchte sind das Lebenselixier des sozialen Miteinanders. Gerüchte können Imperien vernichten und sie können Unternehmen in ungeahnte Höhen katapultieren.
                                                            <br></br>Gut, dass Du uns gefunden hast.
                                                            <br></br>Denn wir sind für letzteres zuständig. Wir, das sind Rabea und Sepp.
                                                            <br></br>Falls Du uns noch nicht kennen solltest: Wir sind Sylter, begeisterte Wassersportler und - wie so
                                                            viele unserer Generation - mit den sozialen Medien groß geworden. Oft werden wir auch als “Influencer” bezeichnet.
                                                            <br></br>Wir haben aus unserem Hobby - Fotografie und Videoerstellung -
                                                            unseren Traumjob gemacht.
                                                            <br></br>Wir verzichten an dieser Stelle auf ein Portfolio unserer Kunden. Das ist nicht gewünscht und auch nicht maßgebend. Denn wir würden Dich gerne selbst überzeugen.
                                                            <br></br>Ein kostenloses Erstgespräch ist selbstverständlich. Danach kannst Du ein Schnupperpaket buchen und dir überlegen, ob eine Zusammenarbeit Dich und uns weiter bringt. Denn gegenseitiges
                                                            Wertschätzen der jeweiligen Arbeit ist ein großer Bestandteil unseres Jobs. Wir legen Wert auf langfristige Zusammenarbeit.
                                                            <br></br>Lass uns gleich anfangen mit den positiven Gerüchten….
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                        {/* <!-- Tabs --> */}
                        <div className="section-full content-inner-2 tab-bx br-top">
                            <div className="container">
                                <div className="">
                                    <Index4Tab />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Tabs End --> */}
                        {/* <!-- Gallery--> */}

                        {/* <div className="section-full content-inner bg-white workspace-bx mfp-gallery">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-head text-center">
                                            <h2 className="text-uppercase ">
                                                <span className="font-weight-300">Kleiner Auszug</span> <br />
                                                unserer Arbeit
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <Gallery />
                            </div>
                        </div> */}
                        {/* <!-- Gallery END --> */}

                        {/* <!-- Pricing Table --> */}

                        {/* <!-- Pricing Table End --> */}
                        {/* <!-- Get in touch --> */}
                        <div className=" bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                        {/* TestimonialCarousel */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
            </>
        );
    }
}
export default Index4;
