import React, { Component } from "react";

import bgimg from "./../../images/main-slider/slide6.jpg";
import logo from "./../../images/logo-color-2.png";

class MainBannerShort extends Component {
    render() {
        return (
            <div className="home-banner home-banner-short" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                <div className="home-bnr-inner">
                    <div className="home-bnr-content">
                        <img className="index-logo" src={logo} alt="Logo" />
                        <div className="home-bnr-btns">
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MainBannerShort;
