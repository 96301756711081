import React, { Fragment, Component } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const FormStyle = () => {
    const form = useRef();
	


    function sendEmail(e) {
        e.preventDefault();
		alert("Vielen Dank für deine Nachricht!");

        emailjs.sendForm("service_dkvg569", "template_hoz6i0s", form.current, "c6tMzBqmP8NcvWW6l").then(
            (result) => {
                console.log(result.text);
				
            },
            (error) => {
                console.log(error.text);
            }
        );
    }



    return (
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-5 content-inner chosesus-content text-white">
                        <h3 className="box-title m-b15 ">
                            Kontaktiere uns<span className="bg-primary"></span>
                        </h3>
                        <p className="font-16 op8">Ruf uns an, sprich uns an, wenn du uns siehst oder schreib einfach eine Nachricht. Wir melden uns so schnell wie möglich zurück.</p>
                        <h4 className="">Wie du uns erreichen kannst:</h4>
                        <ul className="primary no-bullet">
                            <li>
                                <a className="ti-mobile" href="tel:00491742110055">
                                    {" "}
                                    +49 (0) 174 211 0055
                                </a>
                            </li>
                            <li>
                                <a className="ti-email" href="mailto:info@rumours-sylt.de">
                                    {" "}
                                    info@rumours-sylt.de
                                </a>
                            </li>
                            <li>
                                <p className="ti-car"> Kampende 15c, 25980 Sylt</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-md-7 m-b30">
                        <form ref={form} onSubmit={sendEmail} className="inquiry-form  dzForm" action="script/contact.php">
                            <div className="dzFormMsg"></div>
                            <h3 className="box-title m-t0 m-b10">
                                Lass uns deine Ideen in Tatsachen umsetzen <span className="bg-primary"></span>
                            </h3>
                            <p>Schreib uns gerne all deine Fragen, wir versuchen sie so schnell wie möglich zu beantworten.</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti-user text-primary"></i>
                                            </span>
                                            <input name="vorname" type="text" required className="form-control" placeholder="Vorname" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti-user text-primary"></i>
                                            </span>
                                            <input name="nachname" type="text" required className="form-control" placeholder="Nachname" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti-email text-primary"></i>
                                            </span>
                                            <input name="email" type="email" required className="form-control" placeholder="Deine E-Mail" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti-mobile text-primary"></i>
                                            </span>
                                            <input name="telefon" type="text" required className="form-control" placeholder="Telefon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti-agenda text-primary"></i>
                                            </span>
                                            <textarea name="text" rows="6" className="form-control" required placeholder="Was möchtest du uns mitteilen?"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button name="submit" type="submit" value="Send" className="site-button button-lg">
                                        {" "}
                                        <span>Klick hier und es geht los</span>{" "}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormStyle;
