import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer3 extends Component {
    render() {
        return (
                <footer className="site-footer map-footer text-white footer-gradient ">
					<div className="footer-top">
						<div className="container-fluid">
							<div className="row" >
								<div className="col-xl-9 col-md-6 col-lg-6 p-a0">
								 <iframe className="footer-map" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d146829.20758265193!2d8.243331210339674!3d54.899300014245156!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1517832215254"></iframe>
								</div>
								<div className="col-xl-3 col-md-12 col-lg-12">
									<div className="row footer-content-link">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="widget widget_services border-0">
												<h5 className="m-b15 text-white">Wichtige Links</h5>
												<ul>
													<li><Link to={"/contact"}>Kontakt</Link></li>
													<li><Link to={"/impressum"}>Impressum</Link></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="footer-bottom text-left row">
										<div className="col-md-12 col-sm-12 text-left p-lr30"> 
											<span>Copyright &copy; 2022 Maximilian Müller</span> 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
				
        );
    }
}

export default Footer3;