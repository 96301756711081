import React, { Component } from "react";
import { VideoPopup2 } from "../Element/VideoPopup";

import bgimg from "./../../images/main-slider/slide6.jpg";
import video from "./../../images/main-slider/main-slide.mp4";
import logo from "./../../images/logo-color-2.png";

class MainBanner extends Component {
    render() {
        return (
            <div className="home-banner" style={{ backgroundImage: "url(" + video + ")" }}>
                <div className="home-bnr-inner">
                    <div className="home-bnr-content">
                        <img className="index-logo" src={logo} alt="Logo" />
                        {/* <div className="home-bnr-btns">
                            <VideoPopup2 />
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}
export default MainBanner;
