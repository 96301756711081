import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import MainBannerShort from "../Element/MainBannerShort";

import bnr2 from "./../../images/background/bg1.png";
import about3 from "./../../images/about/pic3.jpg";



//Light Gallery on icon click
const Iconimage = (props) => {
    const { openLightbox } = useLightbox();
    return (
        <Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link">
            <i className="ti-fullscreen icon-bx-xs"></i>
        </Link>
    );
};

class Aboutus extends Component {
    render() {
        return (
            <>
                <div className="page-content bg-white rubik">
                    <MainBannerShort />
                    {/* <!-- Gallery--> */}
                    <div className="content-block">
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
                                    <div className="container">
                                        <div className="section-content">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="icon-bx-wraper">
                                                        <div className="icon-md text-black m-b20">
                                                            <div className="icon-cell text-black">
                                                                <i className="flaticon-trophy"></i>
                                                            </div>
                                                        </div>
                                                        <div className="icon-content m-b30">
                                                            <h5 className="dlab-tilte">Elegant / Unique design</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="icon-bx-wraper m-b30">
                                                        <div className="icon-md text-black m-b20">
                                                            <div className="icon-cell text-black">
                                                                <i className="flaticon-technology"></i>
                                                            </div>
                                                        </div>
                                                        <div className="icon-content">
                                                            <h5 className="dlab-tilte">Different Layout Type</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="icon-bx-wraper">
                                                        <div className="icon-md text-black m-b20">
                                                            <div className="icon-cell text-black">
                                                                <i className="flaticon-bar-chart"></i>
                                                            </div>
                                                        </div>
                                                        <div className="icon-content m-b30">
                                                            <h5 className="dlab-tilte">Make it Simple</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="icon-bx-wraper  m-b30">
                                                        <div className="icon-md text-black m-b20">
                                                            <div className="icon-cell text-black">
                                                                <i className="flaticon-devices"></i>
                                                            </div>
                                                        </div>
                                                        <div className="icon-content">
                                                            <h5 className="dlab-tilte">True Responsiveness</h5>
                                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod..</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                        <img src={about3} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>Wir produzieren höchste Qualität zu kostengünstigen Preisen. </h2>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 text-gray">
                                                    <p>
                                                            Wir kreieren visuelle Werbebotschaften, die Ihre Zielgruppe emotional berühren. In wenigen Augenblicken, ziehen wir die Aufmerksamkeit deiner Kunden auf dich und deine Produkte. Wir
                                                            erstellen Ihre Filme und Websites mit Leidenschaft und Liebe zum Detail. Durch digitale Marketingkampagnen erreichen wir Ihre Wunschkunden.
                                                        </p>
                                                        <Link to={"#"} className="site-button radius-xl outline white">
                                                            Read More <i className="fa fa-long-arrow-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Aboutus;
