import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import ThemeButton from './Element/ThemeButton/ThemeButton';

import Homepage from "./Pages/Homepage";
import Aboutus from "./Pages/About";
import Contact from "./Pages/Contact";

import ScrollToTop from "./Element/ScrollToTop";

import Impressum from "./Pages/Impressum";

import Header from "./Layout/Header";
import Footer from "./Layout/Footer";

class Markup extends Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wrapper">
                    <Header />
                    <Switch>
                        <Route path="/" exact component={Homepage} />
                        <Route path="/about" exact component={Aboutus} />
                        <Route path="/contact" exact component={Contact} />
                        <Route path="/impressum" exact component={Impressum} />
                    </Switch>
                    <Footer />
                </div>
                <ScrollToTop />
                {/* <ThemeButton /> */}
            </BrowserRouter>
        );
    }
}

export default Markup;
